'use server';

import { fetchGetCommentsServerActionQuery } from '@haaretz/s-server-queries/GetComments.server.action';
import React from 'react';

import CommentServer from '../CommentServer';
import CommentFormClient from '../CommentServer/client/CommentFormClient';
import SingleCommentWrapper from '../CommentServer/client/SingleCommentWrapper';
import SubCommentsWrapper from '../SubCommentsWrapper';

import type { GetCommentsServerActionQueryVariables } from '@haaretz/s-server-queries/GetComments.server.action';
import type { BaseServerActionParams } from '@haaretz/s-types';

export type SortTypes = 'editorsPick' | 'firstToLast' | 'lastToFirst' | 'rate';

interface GetCommentsResponse {
  totalHits: number;
  html: JSX.Element[];
  next: string | null;
}

const defaultResponse: GetCommentsResponse = {
  totalHits: 0,
  html: [] as JSX.Element[],
  next: null,
};

interface GetCommentsProps extends GetCommentsServerActionQueryVariables, BaseServerActionParams {
  articleId: string;
  subcommentIdParam?: string | null;
  loadedCount: number;
}

export default async function getComments({
  id,
  count,
  sort = 'lastToFirst',
  cursor,
  commentId,
  articleId,
  subcommentIdParam,
  loadedCount,
}: GetCommentsProps) {
  try {
    // NOTE: cursor must be string
    const res = await fetchGetCommentsServerActionQuery({
      id,
      count,
      cursor: cursor || '',
      sort,
      commentId,
    })();
    const commentsList = res.CommentsElement?.comments;

    if (!Array.isArray(commentsList) || commentsList.length === 0) return defaultResponse;

    const totalHits = res.CommentsElement?.totalHits ?? 0;
    const isSingleComment = !!commentId;

    const html = commentsList.map((comment, index) => {
      let commentNumber: number = index + 1;

      if (totalHits) {
        if (sort === 'lastToFirst') {
          commentNumber = totalHits - loadedCount - index;
        } else if (cursor) {
          commentNumber = loadedCount + commentNumber;
        }
      }

      const sharedSubCommentProps = {
        commentsElementId: id,
        isSubComment: true,
        parentCommentId: comment.commentId,
      };

      return (
        <React.Fragment key={comment.commentId}>
          <CommentWithSubCommentsWrapper>
            <CommentServer
              {...comment}
              commentsElementId={id}
              commentNumber={isSingleComment ? undefined : commentNumber}
              parentCommentId={comment.commentId}
              isSingleComment={isSingleComment}
            />
            {comment.subComments?.length ? (
              <SubCommentsWrapper>
                {comment.subComments?.map(subComment => {
                  const isSingleSubComment =
                    subcommentIdParam && subComment.commentId === subcommentIdParam;

                  const subCommentProps = {
                    ...subComment,
                    ...sharedSubCommentProps,
                    replyTo: subComment.replyTo || comment.authorName,
                  };

                  return isSingleSubComment ? (
                    <SingleCommentWrapper key={subComment.commentId}>
                      <CommentServer {...subCommentProps} isSingleSubComment />
                    </SingleCommentWrapper>
                  ) : (
                    <CommentServer {...subCommentProps} key={subComment.commentId} />
                  );
                })}
              </SubCommentsWrapper>
            ) : null}
          </CommentWithSubCommentsWrapper>
          <CommentFormClient articleId={articleId} commentId={comment.commentId} />
        </React.Fragment>
      );
    });

    return {
      html,
      totalHits,
      next: isSingleComment ? null : commentsList[commentsList.length - 1].commentId,
    };
  } catch (error) {
    console.error(`Comments server action failed: ${(error as Error).message}`);

    return defaultResponse;
  }
}

function CommentWithSubCommentsWrapper({ children }: React.PropsWithChildren) {
  return <div>{children}</div>;
}
