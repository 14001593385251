'use client';

import React from 'react';
import s9 from 'style9';

import type { StaticElementProps } from './StaticElement.types';

const c = s9.create({
  defaultPlacement: {
    gridColumnStart: 'gutter',
    gridColumnEnd: 'gutter',
  },
});
export default function StaticElement({ styleExtend = [], rawHtml }: StaticElementProps) {
  const elRef = React.useRef<HTMLElement>(null);

  React.useLayoutEffect(() => {
    const el = elRef.current;

    if (!el || !rawHtml) return;

    const range = document.createRange();
    range.selectNode(el);
    const documentFragment = range.createContextualFragment(rawHtml);

    el.innerHTML = '';
    el.append(documentFragment);
  }, [rawHtml]);

  if (!rawHtml) return null;

  return (
    <figure
      className={s9(c.defaultPlacement, ...styleExtend)}
      data-test="articleHtmlElement"
      ref={elRef}
    />
  );
}
