'use client';

import fork from '@haaretz/l-fork.macro';
import TextField from '@haaretz/s-text-field';
import emailValidator from '@haaretz/s-validators/emailValidator';
import * as React from 'react';
import s9 from 'style9';

import type { TextFieldProps } from '@haaretz/s-text-field';

// `c` is short for `classNames`
const c = s9.create({
  base: {},
  field: {
    height: 'max-content',
    width: '100%',
  },
  fullWidthColumn: {
    gridColumnStart: 'span 2',
  },
});

export type State = 'default' | 'invalid' | 'empty';

type SetState = {
  descriptionState: TextFieldProps['description'];
  /**
   *
   * Used to determine the isInvalid prop.
   */
  stateProp: State;
  /**
   * Used to set the state for the isInvalid prop.
   */
  setStateProp: React.Dispatch<React.SetStateAction<State>>;
  /**
   * Sets the description below the input.
   */
  setDescriptionProp: React.Dispatch<React.SetStateAction<string>>;
};

export type EmailFieldProps = TextFieldProps & { externalState?: SetState };

export default function EmailField({
  externalState,
  styleExtend = [],
  inlineStyle,
  ...props
}: EmailFieldProps) {
  const [internalState, internalSetState] = React.useState<State>('default');
  const state = externalState?.stateProp ? externalState.stateProp : internalState;
  const setState = externalState?.setStateProp ? externalState.setStateProp : internalSetState;

  const textFieldRef = React.useRef<HTMLInputElement | null>(null);

  const value = textFieldRef.current?.value || '';

  const description = externalState?.descriptionState || emailValidator(value).text;

  return (
    <TextField
      {...props}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      name="email"
      data-testid="email-field"
      required
      maxLength={64}
      description={description}
      isInvalid={state !== 'default'}
      label={fork({ default: 'אימייל', hdc: 'Email' })}
      placeholder="you@example.com"
      type="email"
      pattern="\S+@\S+\.\S+"
      onInput={() => {
        if (state !== 'default') {
          setState('default');
          externalState?.setDescriptionProp && externalState.setDescriptionProp('');
        }
      }}
      styleExtend={[c.field, c.fullWidthColumn, ...styleExtend]}
      inlineStyle={inlineStyle}
      onInvalid={e => {
        e.preventDefault();
        setState(e.currentTarget.value ? 'invalid' : 'empty');
      }}
      ref={textFieldRef}
    />
  );
}
